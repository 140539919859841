<template>
  <div style="display: grid; gap: 0.5rem" class="container-xs">
    <AppHeader back-to="/profile" />
    <p class="app-subtitle" style="margin-top: 2rem; text-align: center">
      TRAVEL COMPANIONS
    </p>
    <div class="profile-companions-list">
      <div class="app-card">
        <RouterLink
          class="profile-companions-list-create-button button-primary"
          to="/profile/companions/create"
        >
          <p style="font-size: 1.5em; line-height: 1">+</p>
          <p style="margin-left: 1rem">Add Travel Companion</p>
        </RouterLink>
      </div>
      <div
        v-for="{ name, initials, email, onClick } in companions"
        class="app-card-clickable profile-companions-list-item"
        @click="onClick"
      >
        <div
          style="
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffc4b7;
            border-radius: 100%;
          "
        >
          {{ initials }}
        </div>
        <div>
          <p>{{ name }}</p>
          <p v-if="email">{{ email }}</p>
        </div>
        <IconDropdownRight />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'
import IconDropdownRight from '../../components-rf/icons/common/IconDropdownRight.vue'

export default {
  name: 'ProfileCompanions',
  components: {
    AppHeader,
    IconDropdownRight,
  },
  created() {
    this.getTravelers()
  },
  methods: {
    ...mapActions('TravelerModule', ['getTravelers']),
  },
  computed: {
    ...mapState('UserModule', ['paymentMethods']),
    ...mapState('TravelerModule', ['travelers']),
    ...mapGetters('TitleModule', ['getFullName']),
    companions() {
      return this.travelers.map(traveler => ({
        name: this.getFullName(traveler),
        initials: (traveler.firstName[0] + traveler.lastName[0]).toUpperCase(),
        email: traveler.email,
        onClick: () => {
          this.$router.push(`/profile/companions/edit/${traveler.id}`)
        },
      }))
      // const button = {
      //   value: this.$t('profile.add-new-companion'),
      //   click: () => this.$router.push('/profile/companions/create'),
      // }
    },
  },
}
</script>

<style scoped lang="scss">
.profile-companions-list {
  display: grid;
  gap: 0.5rem;
  & > * {
    padding: 1rem;
  }
}
.profile-companions-list-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
}
.profile-companions-list-create-button {
  display: flex;
  align-items: center;
  justify-content: center;
  // background: var(--color-primary);
  width: 100%;
  padding: 1rem;
  // color: white;
  border-radius: 8px;
}
</style>
